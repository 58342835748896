import React from "react";
import Bridge from "../media/bridge_image.jpg";

const Advantages = () => {
  return (
    <div className="Adv_container">
      <div className="Advantages_container1">
        <div className="Background_color_container1"></div>
        <ul>
          <li>
            <h2>Customized</h2>
            <p>
              Take advantage of the control of designing your systems to your
              exact need
            </p>
          </li>
          <li>
            <h2>Automate</h2>
            <p>Develop workflows and automate the process</p>
          </li>
          <li>
            <h2>Platform</h2>
            <p>Find the right technology platform for your needs </p>
          </li>
        </ul>
      </div>
      <div className="Advantages_container2">
        <div className="Background_image">
          <img src={Bridge} alt="" />
          <div className="Advantages_Foreground_color"></div>
        </div>
        <ul>
          <li>
            <h2>Cloud</h2>
            <p>Take advantage of the cloud where it fits your needs</p>
          </li>
          <li>
            <h2>Consult</h2>
            <p>Over 30 years of technology experience</p>
          </li>
          <li>
            <h2>Connect</h2>
            <p>
              Leverage our professional experience with world class networks{" "}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Advantages;
