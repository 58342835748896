import React from "react";
import ContactForm from "../components/ContactForm";
import "../css/Contact.css";

const Contact = () => {
  return (
    <div className="Home_container">
      <ContactForm />
    </div>
  );
};

export default Contact;
