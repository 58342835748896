import React from 'react';
import DashUpclose from '../media/Dashboard_upclose.JPG';
import Galileoec from '../media/galileoEC.JPG';
import BillpaySingle from '../media/billpaysingle.JPG';
import BillpayPortal from '../media/billpayportal.jpg';
import ConnectionTracker from '../media/conn.JPG';
import ProjectManager from '../media/pm.JPG';
import ECPortal from '../media/ec_portal.JPG';

const Projects = () => {
  return (
    <div className="project_container">
      <div className="page_title">
        <h1>PROJECTS</h1>
      </div>
      <div className="project_item">
        <div className="project_name">
          <h2>Cellular Internet Backup - Customer Portal</h2>
        </div>
        <div className="project_description">
          <h5>
            Customer portal that assist a customer manage and monitor their
            backup internet connectivity for multi-site companies
          </h5>
        </div>
        <div className="flexForPic">
          <div className="flexForPic_left">
            <div className="project_details">
              <h5>High Level Features:</h5>
              <div className="project_li_title">Secure Portal</div>
              <ul>
                <li>Connection Status Tracking </li>
                <li>Internet Usage Pool Tracking </li>
                <li>Cellular Carrier Tracking </li>
                <li>Multi-Tenant (Customer, Sales Agent, Super Admin) </li>
              </ul>
              <div className="project_li_title">Technologies</div>
              <ul>
                <li>React</li>
                <li>Material UI</li>
                <li>NodeJs</li>
                <li>HTML</li>
                <li>Javascript</li>
                <li>3rd Party APIs</li>
              </ul>
            </div>
          </div>
          <div className="flexForPic_right">
            <div className="project_picture">
              <img className="EcPic" src={ECPortal} alt="Project Management" />
            </div>
          </div>
        </div>
      </div>
      <div className="project_item">
        <div className="project_name">
          <h2>Project Management System</h2>
        </div>
        <div className="project_description">
          <h5>System to track projects</h5>
        </div>
        <div className="flexForPic">
          <div className="flexForPic_left">
            <div className="project_details">
              <h5>High Level Features:</h5>
              <div className="project_li_title">Secure Portal</div>
              <ul>
                <li>Multi Projects Tracking </li>
                <li>Tasks tracking </li>
                <li>Task - steps tracking </li>
                <li>KnowledgeBase </li>
                <li>Ticket Tracking </li>
                <li>Contacts </li>
              </ul>
              <div className="project_li_title">Technologies</div>
              <ul>
                <li>React</li>
                <li>Material UI</li>
                <li>NodeJs</li>
                <li>HTML</li>
                <li>Javascript</li>
              </ul>
            </div>
          </div>
          <div className="flexForPic_right">
            <div className="project_picture">
              <img
                className="projectManagerPic"
                src={ProjectManager}
                alt="Project Management"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="project_item">
        <div className="project_name">
          <h2>Connection Manager</h2>
        </div>
        <div className="project_description">
          <h5>
            System to track orders for services to be installed for corporate{' '}
          </h5>
        </div>
        <div className="flexForPic">
          <div className="flexForPic_left">
            <div className="project_details">
              <h5>High Level Features:</h5>
              <div className="project_li_title">Secure Portal</div>
              <ul>
                <li>Online Order form </li>
                <li>Export Data Feature(Excel) </li>
                <li>Automated Order Acknowledgement </li>
                <li>Automated Order Submission to Service Provider </li>
                <li>Automated Reports for Weekly Reports </li>
                <li>Disconnection & Move Order Tracking </li>
              </ul>
              <div className="project_li_title">Technologies</div>
              <ul>
                <li>React</li>
                <li>Material UI</li>
                <li>NodeJs</li>
                <li>HTML</li>
                <li>Javascript</li>
              </ul>
            </div>
          </div>
          <div className="flexForPic_right">
            <div className="project_picture">
              <img
                className="billPayPortalPic"
                src={ConnectionTracker}
                alt="Project Management"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="project_item">
        <div className="project_name">
          <h2>Bill Pay Portal (Phase II)</h2>
        </div>
        <div className="project_description">
          <h5>Portal that allows credit card payment for invoices.</h5>
        </div>
        <div className="flexForPic">
          <div className="flexForPic_left">
            <div className="project_details">
              <h5>High Level Features:</h5>
              <div className="project_li_title">Secure Portal</div>
              <ul>
                <li>Payment Method Repository </li>
                <li>Payment Method Management </li>
                <li>Transaction History </li>
                <li>Make Payments </li>
                <li>PCI Compliant solution </li>
              </ul>
              <div className="project_li_title">Technologies</div>
              <ul>
                <li>React</li>
                <li>Material UI</li>
                <li>NodeJs</li>
                <li>HTML</li>
                <li>Javascript</li>
                <li>Multiple 3rd Party APIs</li>
              </ul>
            </div>
          </div>
          <div className="flexForPic_right">
            <div className="project_picture">
              <img
                className="billPayPortalPic"
                src={BillpayPortal}
                alt="GalileoEC"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="project_item">
        <div className="project_name">
          <h2>Bill Pay Portal (Phase I)</h2>
        </div>
        <div className="project_description">
          <h5>Portal that allows credit card payment for invoices.</h5>
        </div>
        <div className="flexForPic">
          <div className="flexForPic_left">
            <div className="project_details">
              <h5>High Level Features:</h5>
              <div className="project_li_title">Public Site</div>
              <ul>
                <li>Single Payment processing </li>
                <li>PCI Compliant solution </li>
                <li>Email receipt of payment</li>
              </ul>
              <div className="project_li_title">Technologies</div>
              <ul>
                <li>React</li>
                <li>Material UI</li>
                <li>NodeJs</li>
                <li>HTML</li>
                <li>Javascript</li>
              </ul>
            </div>
          </div>
          <div className="flexForPic_right">
            <div className="project_picture">
              <img className="billPayPic" src={BillpaySingle} alt="GalileoEC" />
            </div>
          </div>
        </div>
      </div>
      <div className="project_item">
        <div className="project_name">
          <h2>Galileo Enterprise Connect - Ordering System (Phase I)</h2>
        </div>
        <div className="project_description">
          <h5>
            Custom configuration ordering system for backup internet product.
          </h5>
        </div>
        <div className="flexForPic">
          <div className="flexForPic_left">
            <div className="project_details project_details">
              <h5>High Level Features:</h5>
              <div className="project_li_title">
                Public Site - GalieloEC.com
              </div>
              <ul>
                <li>Marketing frontend </li>
                <li>Service bundles page </li>
                <li>Single or Multi Site order support</li>
                <li>Workflow order processing </li>
                <li>Email confirmation & notifications</li>
              </ul>
              <div className="project_li_title">Content Management System</div>
              <ul>
                <li>Service Bundles</li>
                <li>Orders Database</li>
              </ul>
              <div className="project_li_title">Workflows</div>
              <ul>
                <li>Credit card repository setup via API</li>
                <li>Monthly subscription setup via API </li>
                <li>CRM record created via API </li>
                <li>Sales Order created via API</li>
                <li>Order database updated with order information</li>
              </ul>
              <div className="project_li_title">Technologies</div>
              <ul>
                <li>React</li>
                <li>Material UI</li>
                <li>NodeJs</li>
                <li>MongoDB</li>
                <li>HTML</li>
                <li>Javascript</li>
                <li>Multiple 3rd Party APIs</li>
              </ul>
              <div className="project_li_title">
                Customer portal - (Future Release)
              </div>
              <ul>
                <li>Account Management</li>
                <li>Subscription Management</li>
                <li>Bandwidth Usage Reporting</li>
              </ul>
              <div className="project_li_title">
                Partner portal - (Future Release)
              </div>
              <ul>
                <li>Account Management</li>
                <li>Partner's Customer Subscription Management</li>
                <li>Partner's Customer Bandwidth Usage Reporting</li>
              </ul>
            </div>
          </div>
          <div className="flexForPic_right">
            <div className="project_picture">
              <img className="galileoPic" src={Galileoec} alt="GalileoEC" />
            </div>
          </div>
        </div>
      </div>
      <div className="project_item">
        <div className="project_name">
          <h2>Rescue Manager Application</h2>
        </div>
        <div className="project_description">
          <h5>
            A cloud based software application that supports animal rescue
            organizations to scale the adoption processing in order to save more
            animals
          </h5>
        </div>
        <div className="flexForPic">
          <div className="flexForPic_left">
            <div className="project_details">
              <h5>High Level Features:</h5>
              <div className="project_li_title">
                Adoption & Foster Application Process Tracking
              </div>
              <ul>
                <li>Workflow tasks</li>
                <li>Data collection notes</li>
                <li>Send emails to applicant</li>
                <li>Post approval Follow Up</li>
                <li>File repository</li>
                <li>Adoption Contract Generator</li>
              </ul>
              <div className="project_li_title">
                Adoption Information Repository
              </div>
              <ul>
                <li>
                  Adoption record tied to original application data and animal
                  record
                </li>
              </ul>
              <div className="project_li_title">Animal Records Repository</div>
              <ul>
                <li>Vital Animal Information</li>
                <li>Foster tracking</li>
                <li>Pictures of Animal</li>
                <li>Availability status tracking</li>
                <li>File Repository</li>
                <li>Medication/Vaccine tracking</li>
                <li>Medication/Vaccine Reminder</li>
              </ul>
              <div className="project_li_title">Rescue Contacts Database</div>
              <ul>
                <li>Fosters</li>
                <li>Application Processors</li>
                <li>Transport</li>
                <li>Shelters</li>
                <li>Vets</li>
                <li>Other Partners</li>
              </ul>
              <div className="project_li_title">
                Resource Sharing (Knowledge base)
              </div>
              <ul>
                <li>Proces & Procedure Repositories</li>
                <li>Resource links</li>
              </ul>
              <div className="project_li_title">
                User workflow "Task" tracking
              </div>
              <ul>
                <li>Focus application processors on assigned tasks</li>
              </ul>
              <div className="project_li_title">
                User workflow "Follow Up" tracking
              </div>
              <ul>
                <li>
                  Post Adoption Follow up activities. (example: post adoption
                  spay/neuter records)
                </li>
              </ul>
            </div>
          </div>
          <div className="flexForPic_right">
            <div className="project_picture">
              <img className="rescuePic" src={DashUpclose} alt="Dash Pic" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
