import React from "react";
import ServicesPage from "../components/Services";
import "../css/Services.css";

const Services = () => {
  return (
    <div className="Home_container">
      <ServicesPage />
    </div>
  );
};

export default Services;
