import React from 'react';
import Hero_image from '../media/hero_background.jpg';
import '../css/Hero.css';

const Hero = () => {
  const heroText = 'Custom web applications';

  return (
    <div className="container">
      <div className="Hero_section">
        <img src={Hero_image} alt="" />
        <div className="Blur_foreground"></div>
        <h1 className="Hero_text">{heroText}</h1>
      </div>
    </div>
  );
};

export default Hero;
