import React from "react";
import ProjectsPage from "../components/Projects";
import "../css/Projects.css";

const Projects = () => {
  return (
    <div className="Home_container">
      <ProjectsPage />
    </div>
  );
};

export default Projects;
