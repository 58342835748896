import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [contactInfo, setContactInfo] = useState({
    name: null,
    phone: null,
    email: null,
    message: null,
  });

  const [alert, setAlert] = useState(null);
  const [buttonText, setButtonText] = useState('SEND MESSAGE');

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const onChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.id]: e.target.value });
  };

  const submitContact = async () => {
    setButtonText('SENDING MESSAGE');
    const res = await axios.post('/api/email/', contactInfo, config);
    document.getElementById('contactForm').reset();
    window.scroll(0, 0);
    setAlert(res.data);
    setTimeout(() => {
      setAlert(null);
      setButtonText('SEND MESSAGE');
    }, 3000);
    setContactInfo({
      name: null,
      phone: null,
      email: null,
      message: null,
    });
  };
  return (
    <div className="contactContainer">
      {alert !== null ? <div className="contact_alert">{alert}</div> : null}

      <h1 className="contact_headline">CONTACT US</h1>
      <div className="contactForm">
        <form className="p-3" id="contactForm">
          <div className="form-group mt-1">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              id="name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              id="phone"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              onChange={onChange}
              className="form-control"
              id="email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              onChange={onChange}
              id="message"
              rows="8"
            ></textarea>
          </div>
        </form>
        <div className="row">
          <button
            type="submit"
            onClick={submitContact}
            className="contactButton"
          >
            {buttonText}
          </button>
        </div>
      </div>
      <div className="contactInformation">
        <ul>
          <li className="ContactInformationHeadline">BizApps Services</li>
          <li>
            410-978-0372 - <span className="text-bold">Phone/SMS</span>{' '}
          </li>
          <li>
            gtaggart@taggart-ent.com - <span className="text-bold">Email </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactForm;
