import React from "react";
import Hero from "../components/Hero";
import Advantages from "../components/Advantages";
import "../css/Advantages.css";

const Home = () => {
  return (
    <div className="Home_container">
      <Hero />
      <Advantages />
    </div>
  );
};

export default Home;
