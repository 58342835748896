import React, { useState } from "react";
import Logo from "../media/LOGO.png";
import "../css/Navbar.css";

const Navbar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const navLinks = [
    {
      _id: 1,
      Link_Name: "HOME",
      Link_Address: "/",
    },
    {
      _id: 2,
      Link_Name: "SERVICES",
      Link_Address: "/services",
    },
    {
      _id: 3,
      Link_Name: "PROJECTS",
      Link_Address: "/projects",
    },
    {
      _id: 4,
      Link_Name: "CONTACT",
      Link_Address: "/contact",
    },
  ];

  const toggle_mobile_menu = () => {
    if (showMobileNav) {
      setShowMobileNav(false);
    } else {
      setShowMobileNav(true);
    }
  };

  return (
    <div className="Nav_container">
      <nav>
        <img className="logo" src={Logo} alt="" />
        <div className="Hamburger_menu">
          <svg
            onClick={toggle_mobile_menu}
            className="Hamburger_svg-icon"
            viewBox="0 0 20 20"
          >
            <path
              fill="none"
              d="M3.314,4.8h13.372c0.41,0,0.743-0.333,0.743-0.743c0-0.41-0.333-0.743-0.743-0.743H3.314
								c-0.41,0-0.743,0.333-0.743,0.743C2.571,4.467,2.904,4.8,3.314,4.8z M16.686,15.2H3.314c-0.41,0-0.743,0.333-0.743,0.743
								s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,15.2,16.686,15.2z M16.686,9.257H3.314
								c-0.41,0-0.743,0.333-0.743,0.743s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,9.257,16.686,9.257z"
            ></path>
          </svg>
        </div>
        <div className="Desktop_menu">
          <ul>
            {navLinks.map((link) => (
              <li key={link._id}>
                <a href={link.Link_Address}>{link.Link_Name}</a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      {showMobileNav ? (
        <div className="Mobile_menu">
          <ul>
            {navLinks.map((link) => (
              <li key={link._id}>
                <a href={link.Link_Address}>{link.Link_Name}</a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;
