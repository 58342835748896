import React from 'react';
import Insta from '../media/insta_icon.png';
import Facebook from '../media/fb_icon.png';
import '../css/Footer.css';

const Footer = () => {
  return (
    <div className="footer_Nav_container">
      <div className="footer_copyright">
        <p>BizApps Services &copy; 2022</p>
      </div>
      <div className="social_icons">
        <a href="https://www.instagram.com/bizappsservices/">
          <img src={Insta} alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/BizAppsServices">
          <img src={Facebook} alt="Facebook" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
