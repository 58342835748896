import React from "react";

const Services = () => {
  return (
    <div className="services_container">
      <div className="services_page_title">
        <h1>SERVICES AVAILABLE</h1>
      </div>
      <div className="services_flex">
        <div className="services_available">
          <div className="services_left_side">
            <div className="service_item">Webtool development</div>
            <div className="service_item">Marketing landing pages</div>
            <div className="service_item">Custom Online Forms</div>
            <div className="service_item">
              Custom Content Management Systems (CMS)
            </div>
          </div>
        </div>
        <div className="services_available">
          <div className="services_right_side">
            <div className="service_item">
              Application Programming Interfaces (API)
            </div>
            <div className="service_item">Process automation</div>
            <div className="service_item">Cloud application development</div>
            <div className="service_item">Website repair or refresh</div>
          </div>
        </div>
      </div>
      <div className="services_page_title">
        <h1>AREAS OF PRACTICE</h1>
      </div>
      <div className="services_flex">
        <div className="services_available">
          <div className="services_left_side">
            <div className="service_item">HMTL</div>
            <div className="service_item">JAVASCRIPT</div>
            <div className="service_item">REACT JS</div>
            <div className="service_item">NODEjs</div>
          </div>
        </div>
        <div className="services_available">
          <div className="services_right_side">
            <div className="service_item">EXPRESS</div>
            <div className="service_item">MySQL</div>
            <div className="service_item">AXIOS</div>
            <div className="service_item">BOOTSTRAP</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
